export function displayAlert(message, type = 0, fixed = false) {
  this.$root.$emit("display-alert", {
    message: message,
    type: type,
    fixed: fixed,
  });
}

// capitalize all words
export function capitalizeAll(value) {
  const values = value.split(" ");

  return values
    .map((e) => capitalize(e))
    .toString()
    .replaceAll(",", " ");
}

// capitalize first word
export function capitalize(value) {
  return (
    value.toString().charAt(0).toUpperCase() +
    value.toString().substring(1).toLowerCase()
  );
}
